.blog-card {
  background-color: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.302);
  margin-bottom: 60px;
  border: none;
  border-radius: 0;
  height: 340px;

  .card-content {
    padding: 30px;
    .category-tag {
      border: 1px solid $dark-grey;
      text-align: center;
      line-height: 19px;
      border-radius: 11.5px;
      width: fit-content;
      height: 23px;
      padding: 2px 25px;
    }

    .card-title {
      margin: 30px 0;
      line-height: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .para {
      height: 100px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 45px;
    }
  }

  .blog-thumbnail {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

.additional-posts {
  margin: 50px 0;

  .steps-card {
    .card-body {
      padding-bottom: 0;
  
      .card-text {
        margin-bottom: 10px;
      }
    }
    .card-footer {
      background-color: transparent;
      border: none;
    }
  }
}

.blog-detail-wrapper {
  margin: 35px 0;

  .blog-banner {
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 425px;
      object-fit: cover;
    }
  }

  .blog-content {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    p {
      color: $road-grey;
      
      strong {
        @include font(hind, bold);
        font-size: 24px;
        line-height: 35px;
        color: $road-grey;
        margin-top: 48px;
        margin-bottom: 16px;
      }
    }
  }
}
