.team-canada-section {
  &.hero-section {
    .hero-banner {
      .banner-content {
        box-shadow: none;
        display: block;
        padding: 70px 0;
        text-align: left;

        .sticky-board {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 35px 28px 35px 40px;
          min-width: 550px;
          max-width: 550px;
          margin-left: -40px;

          .heading-area {
            border-left: 3px solid $se-primary-red;

            h1 {
              font-size: 56px;
              text-transform: capitalize;
              padding-left: 28px;
              margin-bottom: 0;

              span {
                font-weight: 600;
              }
            }
          }
        }

        .official-supplier {
          .supplier-logo {
            margin-top: 60px;
          }
        }
      }
    }

    .promotion-banner {
      text-align: left;

      p {
        @include font(hind, bold);
        font-size: 23px;
        line-height: 24px;
        border-left: 3px solid $se-primary-red;
        padding-left: 10px;
      }
    }
  }
}

.goals-achieved {
  margin: 60px 0;

  .para {
    text-align: center;
    @include font(hind, medium);
    font-size: 18px;
    line-height: 28px;
    color: $road-grey;
  }

  .card-content {
    ul {
      text-align: left;
    }
  }
}

.testimony-credits {
  .credits {
    font-size: 12px;
  }

  p {
    text-align: center;
    @include font(hind, medium);
    font-size: 18px;
    line-height: 28px;
    color: $road-grey;
  }
}

.storage-moving {
  margin: 60px 0;

  .sv-info-card {
    .card-header {
      .card-title {
        height: 100%;
      }

      .card-logo-img {
        img {
          height: 65px;
          width: auto;
        }
      }
    }
  }
}

.storage-locations {
  margin: 60px 0;

  .para {
    @include font(hind, medium);
    text-align: center;
  }
}

.french-language {
  .canada-page-cards {
    .sv-info-card {
      .card-body {
        p {
          margin-bottom: 0;
        }
      }

      .click-for-details {
        font-size: 15px;
      }
    }
  }
}
