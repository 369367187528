.hero-section {
  position: relative;
  z-index: 10;
  .hero-banner {
    position: relative;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 516px;
    text-align: center;

    .banner-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .banner-content {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 100vw rgba(0, 18, 29, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h6 {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: $white;
    }

    .search-section {
      margin-top: 30px;
      .search-bar {
        margin: auto;
      }
    }
  }

  .promotion-banner {
    padding: 16px 0;
    background-color: $black-pearl;
    text-align: center;
    p {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 27px;
      color: $white;
      margin-bottom: 0;

      a {
        color: $white;
      }
    }
  }
}


.promotion-banner-general {
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 64px;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  p {
    @include font(hind, semi-bold);
    font-size: 20px;
    line-height: 27px;
    color: $white;
    margin-bottom: 0;
    padding: 14px 0;
    align-content: center;
    margin-right: 12px;
    a {
      color: $white;
    }
  }
  img {
    max-height: 52px;
    align-self: center;
  }
}

.hero-section-left-content {
  position: relative;
  z-index: 10;

  .hero-banner-left-content {
    position: relative;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 433px;
    padding: 0 3rem
  }

  .banner-img-left-content {
    float: right;
    width: 681px;
    height: 433px;
    left: 527px;
    border-radius: 0px 10px 10px 0px;
  }

  .banner-overlay-left-content {
    position: absolute;
    width: 50%;
    height: 433px;
    background: linear-gradient(89.5deg, #E8E8E6 85.03%, rgba(232, 232, 230, 0) 99.61%);
    border-radius: 10px 0px 0px 10px;
    z-index: 1;
  }

  .banner-content-left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    
    position: absolute;
    width: 433px;
    left: 8%;
    bottom: 30px;

    .banner-title {
      line-height: 1;
      font-family: 'Hind';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      letter-spacing: -0.023em;
      
      color: #1E1E1E;
      flex: none;
      order: 0;
      flex-grow: 0;
      z-index: 2;
    }

    .banner-sub-title{
      width: 324px;

      font-family: 'Hind';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;
      /* or 22px */
      letter-spacing: -0.022em;

      color: #1E1E1E;

      z-index: 2;
    }
  }
  .banner-btn {
    z-index: 2;
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  @media (max-width: 990px) {
    .hero-banner-left-content{
      height: 300px;
    }

    .banner-img-left-content {
      height: 300px;
      width: 500px;
    }

    .banner-overlay-left-content {
      height: 300px;
    }


  }
}

@media (max-width: 990px) {
  .hero-section-left-content .banner-content-left-content .banner-title {
    font-size: 40px;
  }
}

@media (max-width : 576px) {
  .hero-section-left-content {
    position: relative;
    z-index: 10;
  
    .hero-banner-left-content {
      position: relative;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      height: 433px;
      padding: 0 2%;
      display: flex;
    flex-direction: column;
    }
  
    .banner-img-left-content {
      width: 100%;
      height: 252px;
      border-radius: 0px 0px 10px 10px;
      position: initial;
      // margin-top: 140px;
    }
  
    .banner-overlay-left-content {
      height: 209px;
      width: 100%;
      background: linear-gradient(180deg,#e8e8e6e0 85.03%,hsla(60,4%,91%,0) 100%);
      border-radius: 10px 10px 0px 0px;
      z-index: 1;
      position: relative;
      margin-bottom: -80px;
    }
  
    .banner-content-left-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 0px;
      left: 5%;
      width: 90%;
      top: 20px;
  
      .banner-title {
        font-size: 36px;
        width: 100%;
      }
  
      .banner-sub-title{
        width: 100%;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
}