@import '../abstracts/figmaVariables';
.se-primary-btn {
  @include se-primary-button;
  background-color: $se-primary-red;

  &.order-online-btn {
    padding: 5px 16px;
  }

  &.btn:hover {
    @include primary-btn-hover-effect;
  }
  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.se-secondary-btn {
  @include se-primary-button;
  background-color: $se-primary-blue;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}


.se-secondary-btn-white {
  height: 100%;
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: $secondary-50;
  &.btn:hover {
    background-color: $secondary-100;
  }

  &.btn:active {
    background-color: $secondary-100;
    border: none;
  }
}

.primary-card-btn {
  @include se-card-buttons;
  background-color: $se-primary-red;

  &.btn:hover {
    @include primary-btn-hover-effect;
  }

  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.secondary-card-btn {
  @include se-card-buttons;
  background-color: $se-primary-blue;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.navy-bold-btn {
  @include se-card-buttons;
  background-color: $navy-blue;
  text-transform: none;
  font-size:16px;
  font-weight:bold;
  color:$white;
  line-height: 1.5;
  img {
    margin-right:6px;
  }

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.sv-primary-red-btn {
  border: none;
  border-radius: 20px;
  background-color: $se-primary-red;
  padding: 7px 12px;
  @include font(hind, bold);
  font-size: 16px;
  line-height: 26px;
  color: $white;
  height: 42px;
  text-decoration: none;

  &.btn:hover {
    @include primary-btn-hover-effect;
  }

  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.article-link {
  background-color: $se-primary-blue;
  border: none;
  border-radius: 0;
  @include font(hind, bold);
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
  padding: 3px 9px;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.learn-more {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 24px;
  color: $road-grey;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: $road-grey;
    text-decoration: underline;
  }
}

.se-primary-outline-btn {
  @include se-primary-button;
  line-height: 16px;
  color: $se-primary-red;
  border: 1px solid $se-primary-red;
  border-radius: 20px;
  padding: 14px 18px;
  text-transform: uppercase;

  &:hover {
    color: $danger;
    border: 1px solid $danger;
  }
}

.se-primary-outline-btn-no-upper-case{
  text-transform: unset!important;
}

.se-primary-outline-btn-no-upper-case{
  text-transform: unset!important;
}

.borderless-btn {
  @include borderless-btn;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.more-less {
  cursor: pointer;
}

.se-primary-header-btn {
  font-size: 16px;
  height: 56px;
}
