.sv-tabs {
  background-color: $navy-blue;
  padding: 13px 0;

  .nav-pills {
    .nav-item {
      margin-right: 30px;
    }
  }

  .nav-link {
    @include font(hind, regular);
    color: $white;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #ffffff;
    border-radius: 11.5px;
    min-width: 115px;
    padding: 2px 30px;
    height: 23px;
    margin: auto;

    &.active {
      background: white;
      color: #0a1632;
    }
  }

  button.nav-link {
    background-color: transparent;
  }
}

.sv-tab-content {
  margin-top: 60px;
  transition: 0.3s;
  animation: fadeIn 3s;
}

.mobile-sv-tabs {
  padding: 13px 8px;

  .slider-container {
    .slider-control-centerleft,
    .slider-control-centerright {
      .left-arrow,
      .right-arrow {
        background-color: $navy-blue;
        border: none;
        color: $white;

        &:disabled {
          display: none;
        }
      }
    }

    .slider-list {
      .slide {
        .nav-link {
          padding: 2px 22px;
          
          &.active {
            background: $white;
            color: $navy-blue;
          }
        }

        .hReno {
          .nav-link {
            margin-left: -1px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
