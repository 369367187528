@import '../abstracts/figmaVariables';
.heading-below-banner {
  margin: 50px 0;
}

.sv-facilities-list {
  cursor: pointer;
  margin-bottom: 15px;

  .image-overlay-info {
    height: 280px;
    .overlay-info {
      padding: 20px;
      box-shadow: none;

      .overlay-img {
        height: 280px;
      }

      h6 {
        position: absolute;
        bottom: 0;
      }
    }

    img {
      object-fit: cover;
    }
  }
}

.sv-store-hours-bold {
  .active-hour {
    td {
      @include font(hind, bold);
    }
  }
}

.sv-explore-section {
  .explore-card {
    @include background-image-prop;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      @include font(hind, medium);
      font-size: 40px;
      line-height: 44px;
      text-align: center;
      color: $white;
      text-transform: capitalize;
      margin: 0 auto;
      padding-bottom: 20px;
      width: 60%;
    }

    &.image-overlay-info {
      &::before {
        box-shadow: none;
        background: none;
      }
    }
  }

  .image-overlay-info {
    .overlay-info {
      box-shadow: none;
      padding: 60px 0;
    }
  }

  .blue-column {
    padding-left: 0;
    padding-right: 0;
  }

  .red-column {
    padding-right: 0;
  }
}

.sv-portable-storage-pricing {
  background-color: $white-grey;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  padding: 50px 0;
  margin-bottom: 86px;

  .sv-large-font {
    font-size: 20px;
    margin-top: 20px;
  }

  .btn {
    font-size: 16px;
    line-height: 16px;
    padding: 7px 14px;
    margin-top: 40px;
    width: 100%;
  }
}

.image-overlay-info {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(3, 3, 3, 0.65) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .overlay-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .overlay-info {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 60px 0;

    .heading {
      @include font(hind, bold);
      font-size: 40px;
      line-height: 60px;
      color: $white;
    }

    .info {
      @include font(hind, regular);
      font-size: 24px;
      line-height: 38px;
      color: $white;
      margin: 20px 0;
    }

    h5 {
      @include font(hind, bold);
      font-size: 28px;
      line-height: 60px;
      color: $white;
      margin-bottom: 30px;
    }
  }
}

.french-language {
  .right_arrange__e058Y {
    padding: 0 0 0 10px;
  }

  .find-unit-size {
    .unit-sizes {
      .details {
        .size-panel {
          .sv-grey-small-text {
            height: 85px;
          }
        }
      }
      .scale-card {
        img {
          width: 100%;
        }
      }
    }
  }

  .overlay-info {
    .title,
    .heading {
      text-transform: none;
    }
  }

  h2,
  h3,
  h4,
  .section-heading {
    text-transform: none;
  }

  .sv-breadcrumb {
    .breadcrumb {
      .breadcrumb-item {
        text-transform: none;
      }
    }
  }

  .steps-card {
    .card-title {
      text-transform: none;
    }
  }

  .sv-explore-section {
    .explore-card {
      .title {
        text-transform: none;
      }
    }
  }

  .deals-detail-wrapper {
    .deal-description {
      .main-heading {
        text-transform: none;
      }
    }
  }

  .team-canada-section {
    .banner-content {
      .heading-area {
        h1 {
          text-transform: none;
        }
      }
    }
  }

  .sv-about-access-storage {
    h4 {
      text-transform: none;
    }
  }

  .contact-short-info {
    p {
      a {
        @include font(hind, bold);
        color: $road-grey;
        text-decoration: none;
        font-size: 14px;
        line-height: 24px;
        word-break: break-all;
      }
    }
  }

  .about-com-article {
    .heading {
      text-transform: none;
    }
  }

  .sv-size-help-guide {
    .form-check {
      .form-check-label {
        // margin-left: 5px;
      }
    }
  }

  .sv-solution-card {
    .card-body {
      .card-title {
        text-transform: none;
      }
    }
  }

  .packing-supplies-wrapper {
    .storage-loc {
      .se-primary-btn {
        font-size: 17px;
      }
    }
  }

  .contact-us-wrapper {
    .sv-contact-details-form {
      .sv-primary-red-btn {
        text-transform: none;
      }
    }
  }

  .sv-our-team-content {
    h3 {
      text-transform: none;
    }
  }

  .FR-btn {
    text-transform: none;
    height: 100%;
  }

  .no-units-found-wrapper {
    .view-units-map {
      .search-move-btn {
        left: 35%;
      }
    }
  }

  .error-brief {
    .sv-primary-red-btn {
      height: 100%;
    }
  }

  .sv-unit-dimensions {
    .sv-grey-bold-small-text {
      font-size: 15px;
    }
  }
}

.figure {
  margin-bottom: 0;
  &.tag {
    position: relative;
    width: 100%;
    &::before {
      @include font(hind, bold);
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      left: 7px;
      border-radius: 6px;
      top: 10%;
      display: block;
      color: $road-grey;
      padding: 3px 5px 0px 5px;
      content: "FEATURED";
      background: $white;
    }
  }
}

.top-left {
  @include font(hind, bold);
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  border-radius: 6px;
  top: 10%;
  left: 7px;
  background-color: $white;
  color: $road-grey;
  padding: 3px 5px 0px 5px;
}

.text-with-no-margin {
  margin-bottom: 0;
}

.sv-filter-container {
  display: grid;
  gap: 24px;

.sv-size-help-guide {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: $gray-50;

  .sv-grey-regular-text {
    @include font(hind, bold);
    color: $dark-grey-color;
    margin-bottom: 5px;
    margin-right: 10px;
    white-space: nowrap;
  }
  .sv-filter-type-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 14px;
    // align-self: stretch;
    .filter-section-title {
      @include font(hind, semi-bold);
      font-size: 14px;
      line-height: 120%;
      color: $gray-400;
      margin-bottom: unset;
    }

    .form-check {
      display: flex;
      gap: 12px;
      align-self: stretch;
      height: 36px;
      .form-check-input {
        width: 22px;
        height: 22px;
        padding: 4px;
        border-radius: 6px;
        cursor: pointer;
        &:checked {
          border-color: $secondary-500;
          background-color: $secondary-500;
          .form-check-label {
            color: $base-white;
            @include font(hind, semi-bold);
          }
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px $gray-300;
        }
      }
      
      .label-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-check-label {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 20px;
          color: $gray-800;
        }

        .form-check-label-disabled {
          color: $gray-400;
        }
  
        .form-check-secondary-label {
          height: 0px;
          @include font(hind, regular);
          font-size: 12px;
          color: $gray-800;
          opacity: 0;
          visibility: hidden;
          transition: visibility .3s, opacity .2s ease-out, height .2s ease-out;
        }

        .form-check-secondary-label-disabled {
          display: none;
        }

        .sv-checkbox-checked-label {
          @include font(hind, semi-bold);
        }
        .form-check-secondary-label-visible
        {            
          visibility: visible;
          opacity: 1;
          height: 12px;
        }
      }

      &:hover {
        .label-container {
          .form-check-label {
            @include font(hind, semi-bold);
          }

          .form-check-label-disabled{
            @include font(hind, regular);
          }
          .form-check-secondary-label {
            visibility: visible;
            opacity: 1;
            height: 12px;
          }
        }
      }

      &:checked {
        .label-container {
          .form-check-label {
            @include font(hind, semi-bold);
          }
          .form-check-secondary-label {
            visibility: visible;
            opacity: 1;
            height: 12px;
          }
        }
      }
    }
  }
}

.closebtn {
  display: flex;
padding: 4px;
justify-content: center;
align-items: center;
justify-self: flex-end;
gap: 8px;
  width: 24px;
  height: 24px;
  border-radius: 8px;
border: 1px solid $base-white;
background: $base-white;

/* Shadows/shadow-xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
cursor: pointer;
}
  .sv-filter-buttons-container {
    width: 100%;
    display: flex;
    gap: 14px;

    .sv-filter-btn {
      width: 100%;
      padding: 10px 8px 8px 8px;
      @include font(hind, regular);
      font-size: 18px;
      line-height: 20px;
      color: $base-black;
      background: $gray-25;
      border-radius: 8px;
      cursor: pointer;
      align-self: stretch;
      transition: background-color 0.3s;
      border-radius: 8px;
      height: 44px;
    }
    .sv-search-btn {
      width: 100%;
      padding: 10px 8px 8px 8px;
      @include font(hind, bold);
      font-size: 18px;
      line-height: 20px;
      color: $base-white;
      background: $brand-500;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
      align-self: stretch;
      border-radius: 8px;
      height: 44px;
    }
  }

}

.sv-size-help-guide {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: $gray-50;

  .sv-grey-regular-text {
    @include font(hind, bold);
    color: $dark-grey-color;
    margin-bottom: 5px;
    margin-right: 10px;
    white-space: nowrap;
  }
  .sv-filter-type-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 14px;
    // align-self: stretch;
    .filter-section-title {
      @include font(hind, semi-bold);
      font-size: 14px;
      line-height: 120%;
      color: $gray-400;
      margin-bottom: unset;
    }

    .form-check {
      display: flex;
      gap: 12px;
      align-self: stretch;
      height: 36px;
      .form-check-input {
        width: 22px;
        height: 22px;
        padding: 4px;
        border-radius: 6px;
        cursor: pointer;
        &:checked {
          border-color: $secondary-500;
          background-color: $secondary-500;
          .form-check-label {
            color: $base-white;
            @include font(hind, semi-bold);
          }
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px $gray-300;
        }
      }
      
      .label-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-check-label {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 20px;
          color: $gray-800;
        }

        .form-check-label-disabled {
          color: $gray-400;
        }
  
        .form-check-secondary-label {
          height: 0px;
          @include font(hind, regular);
          font-size: 12px;
          color: $gray-800;
          opacity: 0;
          visibility: hidden;
          transition: visibility .3s, opacity .2s ease-out, height .2s ease-out;
        }

        .form-check-secondary-label-disabled {
          display: none;
        }

        .sv-checkbox-checked-label {
          @include font(hind, semi-bold);
        }
        .form-check-secondary-label-visible
        {            
          visibility: visible;
          opacity: 1;
          height: 12px;
        }
      }

      &:hover {
        .label-container {
          .form-check-label {
            @include font(hind, semi-bold);
          }

          .form-check-label-disabled{
            @include font(hind, regular);
          }
          .form-check-secondary-label {
            visibility: visible;
            opacity: 1;
            height: 12px;
          }
        }
      }

      &:checked {
        .label-container {
          .form-check-label {
            @include font(hind, semi-bold);
          }
          .form-check-secondary-label {
            visibility: visible;
            opacity: 1;
            height: 12px;
          }
        }
      }
    }
  }
}

.sv-blue-content {
  background-color: $dark-navy-blue-color;
  border: 0.5px solid $road-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 59px;

  .sv-white-bold-text {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

.sv-units-details {
  .bi-plus-square {
    margin-left: 10px;
  }
}

.no-units-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 33px;
    padding: 9px 14px;
    margin-right: 15px;
    font-size: 16px;
    line-height: 17px;
  }
  @media (min-width: 992px) {
    justify-content: start;
  }
}
.text-lt-md-center {
  text-align: center;
  @media (min-width: 992px) {
    text-align: left;
  }
}

.sv-view-units-mobile {
  .sv-view-unit-map-details {
    position: relative;
    top: -50px;
    background-color: $white;
    padding-top: 20px;

    .css-4o1ogd {
      @include primary-scroll;
      min-height: 386px;
      max-height: 1100px;
      overflow: hidden;
    }
  }
}

.sv-storage-units {
  @include smooth-hover-effect;
  @include primary-scroll;
  min-height: calc((100vh - 108px) - 188px);
  max-height: calc((100vh - 108px) - 188px);
  overflow-y: auto;

  .storage-units-card {
    .slider-container {
      .slide {
        img {
          width: 100%;
          object-fit: cover;
          object-position: center left;
        }
      }
    }
  }
}

.slider-container {
  .slider-control-centerleft,
  .slider-control-centerright {
    .arrowBtn {
      width: 24px;
      height: 24px;
      background-color: $white;
      border-radius: 50%;
      padding: 2px;
      border: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      text-align: center;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .slider-control-centerleft {
    .arrowBtn {
      margin: 0px 0px 0px 8px;
    }
  }

  .slider-control-centerright {
    .arrowBtn {
      margin: 0 8px 0 0;
    }
  }
}

.sort-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
}

.cPointer {
  cursor: pointer;
}

.sv-city-landing-units-collapse {
  position: relative;
  top: -20px;
  .accordion {
    .accordion-header {
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.25);
      border-radius: 9px 9px 0px 0px;
      text-align: center;
      .accordion-button {
        @include font(hind, bold);
        display: block;
        background-color: $white;
        color: $road-grey;
        font-size: 16px;
        line-height: 14px;
        &::after {
          display: none;
        }
      }
      .accordion-button[aria-expanded="true"] {
        span {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }

    .accordion-body {
      padding: 10px;
    }
  }
}

.rating-stars {
  margin-right: 10px;
}

.grecaptcha-badge { 
  visibility: hidden; 
}

.sv-read-more-expand {
  margin-bottom: 20px;
  // overflow: hidden;
}

.sv-box-border {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid;
  border-color: #e0e0e0;
}

.sv-safe-car-storage {
  .card-body {
    .card-text {
      height: 59px;
    }
  }
}

.view-units-wrapper {
  .view-units-map {
    min-height: calc(100vh - 100px);
  }
}

.card-body-padding {
  padding: 12px;
}

.text-normal {
  text-transform: none;
}

.sv-view-units-btn-style {
  height: 31px;
  padding: 4px 13px;
}

.swipe-Drawer {
  .css-1jmnfe1 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    overflow: scroll;
  }

  .css-egytxq {
    top: -54px;
  }
}

.sv-mission-sm-content {
  display: none;
}

.sv-tab-location-details {
  .map-infoCardsmall {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
    .info-card-img {
      object-fit: cover;
      border-radius: 9px 0 0 9px;
      object-position:center left;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .para,
  .sv-grey-bold-small-text {
    font-size: 14px;
    line-height: 24px;
  }
}

.city-landing-wrapper {
  .sv-view-unit-map-details {
    .sv-divider-line {
      display: none;
    }
  }
}

a {
  color: $secondary;
}

.sv-swipeable-drawer-line {
  width: 54px;
  height: 4px;
  background-color: $road-grey;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: calc(50% - 27px);
}

.ReactSwipeableBottomSheet--open {
  position: relative;

  .swipa {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 2;
    width: 100%;
    border-radius: 9px 9px 0 0;
    border-bottom: 0.5px solid #bfbfbf;
  }

  .sv-store-locator-location-content {
    position: relative;
    padding-top: 70px;
  }
}

.ReactSwipeableBottomSheet--closed,
.ReactSwipeableBottomSheet--open {
  border-radius: 9px 9px 0px 0px;
}

.css-1jmnfe1 {
  padding-right: 0;
  overflow: hidden;
}

.custom-tooltip {
  &.tooltip {
    .tooltip-arrow {
      &::before {
        border-bottom-color: rgba(0, 0, 0, 0.65);
      }
    }

    .tooltip-inner {
      width: 150px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.65);
      @include font(hind, medium);
      font-size: 12px;
      line-height: 16px;
      text-align: left;
    }
  }
}


.sv-page {
  margin-top: 120px;

  .section {
    margin-top: 40px;
  }
}